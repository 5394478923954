<template>
  <v-container>
    <v-row>
      <v-col class="mt-15">
        <v-card>
          <v-toolbar
              color="teal darken-4"
              dark
              flat
          >
            <v-toolbar-title>Rohdaten</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon title="Als Exceldatei exportieren" @click="excelExport">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>

            <template v-slot:extension>
              <v-tabs
                  v-model="tab"
                  background-color="transparent"
                  grow
              >
                <v-tab key="insolvency">Insolvenzdaten</v-tab>
                <v-tab key="indebtness">Überschuldungsdaten</v-tab>
              </v-tabs>
            </template>
          </v-toolbar>

          <v-tabs-items v-model="tab">
            <v-tab-item key="insolvency">
              <v-data-table
                  :headers="insolvencyHeaders"
                  :items="insolvencyData"
                  :items-per-page=50
                  class="elevation-1"
              >
                <template v-slot:item.summary.status="{ item }">
                  <v-chip
                      :color="item.summary.status.toLowerCase()"
                      dark
                      x-small
                  >
                  </v-chip>
                </template>

              </v-data-table>
            </v-tab-item>
            <v-tab-item key="indebtness">
              <v-data-table
                  :headers="indebtnessHeader"
                  :items="indebtnessData"
                  :items-per-page=50
                  class="elevation-1"
              >
                <template v-slot:item.summary.status="{ item }">
                  <v-chip
                      :color="item.summary.status.toLowerCase()"
                      dark
                      x-small
                  >
                  </v-chip>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-btn color="primary" @click="fetchData">Reload</v-btn>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'FinancialRawDataView',
  props: {},
  data: () => ({
    tab: '',
    showError: false,
    error: null,
    loading: false,
    insolvencyHeaders: [
      {
        text: 'Datum',
        align: 'start',
        sortable: true,
        value: 'created',
      },
      { text: 'Ergebnis', align: 'start', value: 'summary.status' },
      { text: 'Kontostand (Heute)', align: 'end', value: 'balance' },
      { text: 'Kreditlinie (Heute)', align: 'end', value: 'creditLimit' },
      { text: 'Barmittel (Heute)', align: 'end', value: 'cash' },
      { text: 'Verbindlichkeiten (Heute)', align: 'end', value: 'currentDebt' },
      { text: 'Finanzeinzahlungen', align: 'end', value: 'financialDeposits' },
      { text: 'Erfolgseinzahlungen', align: 'end', value: 'profitDeposits' },
      { text: 'Finanzauszahlungen', align: 'end', value: 'financialPayments' },
      { text: 'Erfolgsauszahlungen', align: 'end', value: 'profitPayments' },
      { text: 'Verbindlichkeiten (3 Wochen)', align: 'end', value: 'expectedDebt' },
    ],
    insolvencyData: [],
    indebtnessHeader: [
      {
        text: 'Datum',
        align: 'start',
        sortable: true,
        value: 'created',
      },
      { text: 'Ergebnis', align: 'start', value: 'summary.status' },
      { text: 'Ausstehende Einlagen', align: 'end', value: 'assets.outstandingDeposits' },
      { text: 'Grundstück und Gebäude, grundstücksgleiche Rechte', align: 'end', value: 'assets.realEstate' },
      { text: 'Maschinen, maschinelles Anlagevermögen', align: 'end', value: 'assets.machineryEquipment' },
      { text: 'Fuhrpark', align: 'end', value: 'assets.vehicles' },
      { text: 'Betriebs- und Geschäftsausstattung', align: 'end', value: 'assets.officeEquipment' },
      { text: 'Beteiligungen', align: 'end', value: 'assets.investments' },
      { text: 'Roh-, Hilfs- u. Betriebsstoffe', align: 'end', value: 'assets.materialsAndSupplies' },
      { text: 'Halbfertige Arbeiten', align: 'end', value: 'assets.workInProgress' },
      { text: 'Forderungen aus Lieferungen u. Leistungen', align: 'end', value: 'assets.tradeAccountClaims' },
      { text: 'Sonstige Forderungen', align: 'end', value: 'assets.otherClaims' },
      { text: 'Kassenbestand', align: 'end', value: 'assets.cash' },
      { text: 'Guthaben bei Kreditinstituten', align: 'end', value: 'assets.balances' },
      { text: 'Schadensersatz- u. Erstattungsansprüche', align: 'end', value: 'assets.reimbursements' },

      { text: 'Rückstellungen', align: 'end', value: 'liabilities.accruals' },
      { text: 'Verbindlichkeiten aus Lieferungen u. Leistungen', align: 'end', value: 'liabilities.tradeAccountLiabilities' },
      { text: 'Verbindlichkeiten gegenüber Kreditinstituten', align: 'end', value: 'liabilities.bankLiabilities' },
      { text: 'Verbindlichkeiten gegenüber verbundenen Unternehmen', align: 'end', value: 'liabilities.affiliateLiabilities' },
      { text: 'Steuern und Abgaben', align: 'end', value: 'liabilities.taxesAndDuties' },
      { text: 'Löhne und Gehälter', align: 'end', value: 'liabilities.wagesAndSalaries' },
      { text: 'Sozialversicherungsbeiträge', align: 'end', value: 'liabilities.socialSecurityContributions' },
      { text: 'Sonstige Verbindlichkeiten', align: 'end', value: 'liabilities.otherLiabilities' },
    ],
    indebtnessData: []
  }),
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    console.log("FinancialRawDataView::created");
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData() {
      console.log("FinancialRawDataView::fetchData");
      this.error = this.post = null
      this.loading = true
      let self = this;
      this.$http.get(`${process.env.VUE_APP_ENDPOINT}/admin/financialdata`)
          .then(function (response) {
            self.insolvencyData = response.data.insolvencyData;
            self.indebtnessData = response.data.indebtnessData;
          })
          .catch(function (error) {
            if (error.response.status === 500) {
              self.error = "Da ist was schief gelaufen...";
            } else {
              self.error = error.response;
            }
            self.showError = true
          })
          .finally(() => this.loading = false)
      ;
    },
    excelExport() {
      window.open(`${process.env.VUE_APP_ENDPOINT}/admin/financialdata/export.xlsx`)
    }
  }
}
</script>
